import { PreAnnotationConfig, PreAnnotationConfigFactory } from '../views/AnnotationDetails/views/PreAnnotation/entities';
import { ToolStateFactory } from '../views/ToolView/entities/factories';
import { IToolState } from '../views/ToolView/entities/interfaces';
import { AnnotationCreateStateFactory, IAnnotationCreateState } from './IAnnotationCreateState';
import { AnnotationUploadStateFactory, IAnnotationUploadState } from './IAnnotationUploadState';
import { CommonStateFactory, ICommonState } from './ICommonState';
import { CurrentAnnotationStateFactory, ICurrentProjectState } from './ICurrentProjectState';
import { IVisualComparisonState, VisualComparisonStateFactory } from './IVisualComparison';

export interface IAnnotationState {
    toolState: IToolState;
    currentProjectState: ICurrentProjectState;
    commonState: ICommonState;
    uploadState: IAnnotationUploadState;
    visualComparisonState: IVisualComparisonState;
    preAnnotationConfig: PreAnnotationConfig;
    annotationCreateState: IAnnotationCreateState;
}

export function AnnotationStateFactory(data?: Partial<IAnnotationState>): IAnnotationState {
    return {
        toolState: ToolStateFactory(data?.toolState),
        currentProjectState: CurrentAnnotationStateFactory(data?.currentProjectState),
        commonState: CommonStateFactory(data?.commonState),
        uploadState: AnnotationUploadStateFactory(data?.uploadState),
        visualComparisonState: VisualComparisonStateFactory(data?.visualComparisonState),
        preAnnotationConfig: PreAnnotationConfigFactory(),
        annotationCreateState: AnnotationCreateStateFactory(),
    };
}

export * from './AiAssistance';
export * from './annotation.factory';
export * from './annotation.interface';
export * from './IAgreementRes';
export * from './IAnnotation3D';
export * from './IAnnotationCreateState';
export * from './IAnnotationDetails';
export * from './IAnnotationLog';
export * from './IAnnotationTracking';
export * from './IAnnotationUploadState';
export * from './ICommonState';
export * from './ICurrentProjectState';
export * from './INewProjectState';
export * from './IVisualComparison';
export * from './LabelingConfiguration';


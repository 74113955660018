import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { _entries, DicomViewerControlTool, IWindowLevel } from 'common';
import { BrushConfig, DicomViewerState, DicomViewerStateFactory, IGridLayout, IViewportState } from '../entities';

export const DicomViewerSlice = createSlice({
    name: 'DicomViewer',
    initialState: DicomViewerStateFactory(),
    reducers: {
        updateDicomViewerState(state, { payload }: PayloadAction<Partial<DicomViewerState>>) {
            Object.assign(state, payload);
        },
        updateLabelMapVisibilityState(state, { payload }: PayloadAction<{ label: string; value: boolean }>) {
            state.labelMapVisibility[payload.label] = payload.value;
        },
        updateLabelMapColorState(state, { payload }: PayloadAction<{ label: string; value: string }>) {
            state.labelColorMap[payload.label] = payload.value;
        },
        updateBrushConfigState(state, { payload }: PayloadAction<Partial<BrushConfig>>) {
            Object.assign(state.brushConfig, payload);
        },
        updateEraserConfigState(state, { payload }: PayloadAction<Partial<BrushConfig>>) {
            Object.assign(state.eraserConfig, payload);
        },
        updateCinePlayState(state, { payload }: PayloadAction<boolean>) {
            state.cinePlay = payload;
        },
        updateFrameRate(state, { payload }: PayloadAction<number>) {
            state.frameRate = payload;
        },
        updateGridView(state, { payload }: PayloadAction<IGridLayout>) {
            state.gridView = payload;
        },
        updateCineMode(state, { payload }: PayloadAction<boolean>) {
            state.cineMode = payload;
        },
        updateActiveViewport(state, { payload }: PayloadAction<number>) {
            state.activeViewport = payload;
        },
        updateWindowLevel(state, { payload }: PayloadAction<IWindowLevel>) {
            state.windowLevel = payload;
        },
        updateSelectedLabel(state, { payload }: PayloadAction<string>) {
            state.selectedLabel = payload;
        },
        updatePrefetch(state, { payload }: PayloadAction<boolean>) {
            state.prefetch = payload;
        },
        updateSmartSegmentationEnabled(state, { payload }: PayloadAction<boolean>) {
            state.smartSegmentationEnabled = payload;
        },
        updateBrushRadius(state, { payload }: PayloadAction<number>) {
            state.brushRadius = payload;
        },
        updateSegmentOpacity(state, { payload }: PayloadAction<number>) {
            state.segmentOpacity = payload;
        },
        updateThresholds(state, { payload }: PayloadAction<[number, number]>) {
            state.thresholds = payload;
        },
        updateActiveAnnotationTool(state, { payload }: PayloadAction<DicomViewerControlTool>) {
            state.activeAnnotationTool = payload;
        },
        updateIsViewportFocused(state, { payload }: PayloadAction<boolean>) {
            state.isViewportFocused = payload;
        },
        updateViewportStateMap(state, { payload }: PayloadAction<Record<number, IViewportState>>) {
            _entries(payload).forEach(([key, value]) => {
                Object.assign(state?.viewportStateMap?.[key], value);
            });
        },
        setViewportState(state, { payload }: PayloadAction<{ index: number; newState: IViewportState }>) {
            state.viewportStateMap[payload.index] = payload.newState;
        },
        updateAllViewportState(state, { payload }: PayloadAction<IViewportState>) {
            _entries(state.viewportStateMap).forEach(([key]) => {
                state.viewportStateMap[key] = payload;
            });
        },
        updateLabelLockMap(state, { payload }: PayloadAction<Dictionary<boolean>>) {
            state.labelLockMap = payload;
        },
        updateLabelLock(state, { payload }: PayloadAction<{ label: string; value: boolean }>) {
            state.labelLockMap[payload.label] = payload.value;
        },
    },
});

export const {
    updateDicomViewerState,
    updateLabelMapVisibilityState,
    updateLabelMapColorState,
    updateActiveAnnotationTool,
    updateActiveViewport,
    updateBrushConfigState,
    updateBrushRadius,
    updateCineMode,
    updateCinePlayState,
    updateEraserConfigState,
    updateFrameRate,
    updateGridView,
    updateIsViewportFocused,
    updatePrefetch,
    updateSegmentOpacity,
    updateSelectedLabel,
    updateSmartSegmentationEnabled,
    updateWindowLevel,
    setViewportState,
    updateViewportStateMap,
    updateAllViewportState,
    updateThresholds,
    updateLabelLock,
    updateLabelLockMap,
} = DicomViewerSlice.actions;

export default DicomViewerSlice.reducer;

export * from './selectors';


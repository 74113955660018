export interface LabelMaps3DReturnType {
    activeLabelmapIndex: number;
    currentImageIdIndex: number;
    labelmaps3D: LabelMap3D[];
}

export interface LabelMap3D {
    activeSegmentIndex: number;
    buffer: ArrayBuffer;
    colorLUTIndex: number;
    labelmaps2D: LabelMap2D[];
    metadata: Array<SegmentationMetadata>;
    redo: Array<any>;
    segmentsHidden: boolean[];
    undo: Array<Array<IHistoryOperation>>;
    key?: string;
}

export interface LabelMap2DReturnType {
    activeLabelmapIndex: number;
    currentImageIdIndex: number;
    labelmap2D: LabelMap2D;
    labelmap3D: LabelMap3D;
}

export interface LabelMap2D {
    pixelData: Float32Array;
    segmentsOnLabelmap: number[];
}

export interface Segmentation {
    id: string;
    labelmapIndex: number;
    segmentIndex: number;
    color: [number, number, number, number]; // [r, g, b, a]
    meta: Dictionary;
    name: string;
    description: string;
}

export interface Color {
    r: number;
    g: number;
    b: number;
    a?: number;
}

// {
//     SegmentedPropertyCategoryCodeSequence: {
//         CodeValue: 'T-D0050',
//         CodingSchemeDesignator: 'SRT',
//         CodeMeaning: 'Tissue',
//     },
//     SegmentNumber: (segmentIndex + 1).toString(),
//     SegmentLabel: 'Tissue ' + (segmentIndex + 1).toString(),
//     SegmentAlgorithmType: 'SEMIAUTOMATIC',
//     SegmentAlgorithmName: 'Gesund AI',
//     SegmentedPropertyTypeCodeSequence: {
//         CodeValue: 'T-D0050',
//         CodingSchemeDesignator: 'SRT',
//         CodeMeaning: 'Tissue',
//     },
// };

export interface CodeSequence {
    CodeValue: string;
    CodingSchemeDesignator: string;
    CodeMeaning: string;
}
export interface SegmentationMetadata {
    SegmentedPropertyCategoryCodeSequence?: CodeSequence;
    SegmentNumber?: number;
    SegmentLabel?: string;
    SegmentAlgorithmType?: string;
    SegmentAlgorithmName?: string;
    SegmentedPropertyTypeCodeSequence?: CodeSequence;
    SegmentDescription?: string;
}

export const SegmentationMetadataFactory = (metadata: SegmentationMetadata = {}): SegmentationMetadata => {
    return Object.assign(
        {
            SegmentedPropertyCategoryCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
            SegmentNumber: 1,
            SegmentLabel: 'label-0-1',
            SegmentAlgorithmType: 'SEMIAUTOMATIC',
            SegmentAlgorithmName: 'Gesund AI',
            SegmentedPropertyTypeCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
        },
        metadata
    );
};

export interface StackState {
    currentImageIdIndex: number;
    imageIds: string[];
    pending: [];
    uuid: string;
}

export interface SavedSegmentation {
    mask: string;
    label: string;
    name: string;
    area: number;
    imageId: string;
    shape: Array<number>;
    seriesId: string;
    instanceId: string;
}

export interface ICreateSegmentListArgs {
    labelList: string[];
    colorMap: Dictionary<string>;
    seriesId?: string;
    viewportIndex?: number;
    onColorList?: (colorList: { color: Color; label: string }[]) => void;
    labelMapKey?: string;
}

export interface IApplyMaskAnnotationsArgs {
    imageId: string;
    maskList: Array<IMaskItem>;
    viewportIndex?: number;
    labelMapIndex?: number;
    shape?: [number, number];
    seriesId?: string;
    maskListKey?: string;
}

export interface IApplyLabelMap2DMask {
    maskList: Array<IMaskItem>;
    shape: [number, number];
    labelmap3D: LabelMap3D;
    currentLabelMapIndex: number;
    imageIdIndex: number;
    element: HTMLElement;
}

export interface IMaskItem {
    id: string;
    mask: string;
    label: string;
    shape: [number, number];
}

export interface IHistoryOperation {
    imageIdIndex: number;
    diff: Array<[number, number, number]>;
}

export enum ReplaceMode {
    undo = 1,
    redo = 2,
}

export interface LabelmapBuffer {
    labelmapIndex: number;
    bytesForVoxel: number;
    type: 'Uint16Array' | 'Float32Array';
    buffer: ArrayBuffer;
    colorLUT: Array<[number, number, number, number]>;
}

export type ColorLut = Array<[number, number, number, number]>;

export interface Getters {
    activeLabelmapBuffer: (element: HTMLElement) => any;
    activeLabelmapIndex: (element: HTMLElement) => number;
    activeSegmentIndex: (element: HTMLElement, labelmapIndex?: number) => number;
    brushColor: (element: HTMLElement) => `rgba(${number}, ${number}, ${number}, ${number} )`;
    colorForSegmentIndexColorLUT: (labelmap3DOrColorLUTIndex: number, segmentIndex: number) => [number, number, number, number];
    colorLut: (labelmap3DOrColorLUTIndex: number) => Array<[number, number, number, number]>; // [r, g, b, a]
    isSegmentVisible: (element: HTMLElement, segmentIndex: number, labelmapIndex: number) => boolean;
    labelmap2D: (element: HTMLElement) => LabelMap2DReturnType;
    labelmap2DByImageIdIndex: (element: HTMLElement, imageIdIndex: number, rows: any, columns: any) => LabelMap2D;
    labelmap3D: (element: HTMLElement, labelmapIndex?: number) => LabelMap3D;
    labelmapBuffers: (element: HTMLElement, labelmapIndex: number) => Array<LabelmapBuffer>;
    labelmapStats: (element: HTMLElement, segmentIndex: number, labelmapIndex: number) => any;
    labelmaps3D: (element: HTMLElement) => LabelMaps3DReturnType;
    metadata: (element: HTMLElement, labelmapIndex: number, segmentIndex: number) => SegmentationMetadata;
    segmentOfActiveLabelmapAtEvent: (event: any) => any;
}

export interface BrushToolState {
    colorLutTables: Array<ColorLut>;
    series: Dictionary<BrushToolSeriesState>;
}

export interface BrushToolSeriesState {
    activeLabelmapIndex: number;
    labelmaps3D: LabelMap3D[];
}

export interface SegmentationModule {
    state: BrushToolState;
    getters: Getters;
    setters: any;
    configuration: any;
    onRegisterCallback: any;
}

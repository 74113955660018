import { IWindowLevel } from 'common/enum';
import { DicomViewerControlTool } from 'common/helpers/DicomViewerHelper/interface';
import { IViewportState } from './ViewportState';
import { IGridLayout } from './interfaces';

export interface DicomViewerState {
    cinePlay: boolean;
    frameRate: number;
    gridView: IGridLayout;
    cineMode: boolean;
    activeViewport: number;
    windowLevel: IWindowLevel;
    selectedLabel: string;
    prefetch: boolean;
    smartSegmentationEnabled: boolean;
    brushRadius: number;
    segmentOpacity: number;
    thresholds: [number, number];
    brushConfig: BrushConfig;
    eraserConfig: BrushConfig;
    activeAnnotationTool: DicomViewerControlTool;
    isViewportFocused: boolean;
    labelMapVisibility: Dictionary<boolean>;
    labelColorMap: Dictionary<string>;
    viewportStateMap: Record<number, IViewportState>;
    labelLockMap: Dictionary<boolean>;
}

export function DicomViewerStateFactory(data?: Partial<DicomViewerState>): DicomViewerState {
    return {
        cinePlay: false,
        frameRate: 22,
        gridView: { rows: 1, columns: 1 },
        cineMode: false,
        activeViewport: 0,
        windowLevel: null,
        selectedLabel: '',
        prefetch: true,
        smartSegmentationEnabled: false,
        brushRadius: 10,
        segmentOpacity: 255,
        thresholds: [0, 0],
        brushConfig: {
            opacity: 1,
            lineWidth: 5,
            dashed: false,
            filled: false,
        },
        eraserConfig: {
            opacity: 1,
            lineWidth: 5,
            dashed: false,
            filled: false,
        },
        activeAnnotationTool: undefined,
        isViewportFocused: false,
        labelMapVisibility: {},
        labelColorMap: {},
        viewportStateMap: {},
        labelLockMap: {},
        ...data,
    };
}

export interface BrushConfig {
    opacity: number;
    lineWidth: number;
    dashed: boolean;
    filled: boolean;
}
